import { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

import { putUpdateUser } from "../../../api/admin/users";
import { getClients } from "../../../api/admin/clients";

const EditUserDialog = ({ user, populateUsers, toast }) => {
	const [visible, setVisible] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [clients, setClients] = useState([]);

	const [firstName, setFirstName] = useState(user.first_name);
	const [lastName, setLastName] = useState(user.last_name);
	const [email, setEmail] = useState(user.email);
	const [username, setUsername] = useState(user.username);
	const [clientID, setClientID] = useState(user.client_id);
	const [isAdmin, setIsAdmin] = useState(user.is_admin);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSubmitted(true);
		setLoading(true);

		if (!firstName || !lastName || !email || !username) {
			setLoading(false);
			return;
		}

		if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: "Invalid email address.",
			});
			setLoading(false);
			return;
		}

		if (!isAdmin && !clientID) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: "A non-admin user must be assigned to a client.",
			});
			setLoading(false);
			return;
		}

		user.first_name = firstName;
		user.last_name = lastName;
		user.email = email;
		user.username = username;
		user.client_id = clientID;
		user.is_admin = isAdmin;

		try {
			await putUpdateUser(user);
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: "User updated successfully.",
			});

			populateUsers();
			setVisible(false);
		} catch (err) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: err.message,
			});
		} finally {
			setLoading(false);
		}
	};

	const reset = () => {
		setFirstName(user.first_name);
		setLastName(user.last_name);
		setEmail(user.email);
		setUsername(user.username);
		setClientID(user.client_id);
		setIsAdmin(user.is_admin);
		setSubmitted(false);
	};

	useEffect(() => {
		getClients()
			.then((res) => {
				setClients(res);
			})
			.catch((err) => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: err.message,
				});
			});
	}, [toast]);

	return (
		<>
			<Button
				label="Edit"
				icon="pi pi-pencil"
				className="p-button-primary p-mr-2 p-button-sm"
				onClick={() => setVisible(true)}
			/>

			<Dialog
				header="Edit User"
				visible={visible}
				className="w-4/5 md:w-1/3"
				modal
				onHide={() => {
					setVisible(false);
					reset();
				}}>
				<div className="my-2">
					<label htmlFor="first_name" className="block text-sm my-1">
						First Name
					</label>
					<InputText
						id="first_name"
						required
						autoFocus
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={firstName}
						onInput={(e) => setFirstName(e.target.value)}
					/>
					{submitted && !firstName && <small className="p-error">First Name is required.</small>}
				</div>
				<div className="my-2">
					<label htmlFor="last_name" className="block text-sm my-1">
						Last Name
					</label>
					<InputText
						id="last_name"
						required
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={lastName}
						onInput={(e) => setLastName(e.target.value)}
					/>
					{submitted && !lastName && <small className="p-error">Last Name is Required.</small>}
				</div>
				<div className="my-2">
					<label htmlFor="email" className="block text-sm my-1">
						Email
					</label>
					<InputText
						id="email"
						required
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={email}
						onInput={(e) => setEmail(e.target.value)}
					/>
					{submitted && !email && <small className="p-error">Email is Required.</small>}
				</div>
				<div className="my-2">
					<label htmlFor="username" className="block text-sm my-1">
						Username
					</label>
					<InputText
						id="username"
						required
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={username}
						onInput={(e) => setUsername(e.target.value)}
					/>
					{submitted && !username && <small className="p-error">Username is Required.</small>}
				</div>
				<div className="my-2">
					<label htmlFor="client" className="block text-sm my-1">
						Client
					</label>
					<Dropdown
						id="client"
						required
						className="block w-full p-inputtext-sm"
						value={clientID}
						options={clients}
						onChange={(e) => setClientID(e.value)}
						optionLabel="name"
						optionValue="ID"
						placeholder="Select a Client"
						showClear
					/>
				</div>
				<div className="my-4">
					<Checkbox
						id="is_admin"
						required
						className="block w-full"
						checked={isAdmin}
						onChange={(e) => {
							setIsAdmin(e.checked);
						}}
					/>
					<label htmlFor="is_admin" className="ml-2 text-sm my-1">
						Is Admin
					</label>
				</div>

				<div className="flex justify-end mt-4">
					<Button
						label="Cancel"
						icon="pi pi-times"
						className="p-button-text"
						onClick={() => {
							reset();
							setVisible(false);
						}}
					/>
					<Button
						label="Save"
						icon="pi pi-check"
						className="p-button-text"
						onClick={handleSubmit}
						loading={loading}
						disabled={loading}
					/>
				</div>
			</Dialog>
		</>
	);
};

export default EditUserDialog;
