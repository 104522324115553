import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { useParams, Link } from "react-router-dom";

import CartContext from "../../context/cart";

import { getProduct } from "../../api/shop/products";

import { Image } from "primereact/image";
import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";

import Loading from "../../components/Layout/Loading";
import NotFound from "../../components/Layout/Not-Found";
import FileUploadInput from "../../components/Shop/Product-Form/FileUpload";
import BackgroundSelect from "../../components/Shop/Product-Form/Background-Select";

const Product = () => {
	const { sku } = useParams();
	const { setCartDrawerVisible, populateCart, addToCart } = useContext(CartContext);
	const [product, setProduct] = useState({});
	const [loading, setLoading] = useState(true);
	const [customOptions, setCustomOptions] = useState({});
	const [quantity, setQuantity] = useState(1);
	const [errorMessage, setErrorMessage] = useState("");

	const toast = useRef(null);

	const populateProduct = useCallback(async () => {
		try {
			const product = await getProduct(sku);
			setProduct(product);

			document.title = `${product.name}`;
		} catch (error) {
			setProduct({});
		} finally {
			setLoading(false);
		}
	}, [sku]);

	useEffect(() => {
		if (errorMessage && toast && toast.current !== null) {
			toast.current.show({ severity: "error", detail: errorMessage });
		}
		setErrorMessage("");
	}, [errorMessage, toast]);

	useEffect(() => {
		populateProduct();
	}, [populateProduct]);

	const handleAddToCart = async () => {
		setLoading(true);

		if (product.is_custom) {
			for (const option in product.custom_options) {
				if (product.custom_options[option].includes("required") && !customOptions[option]) {
					setErrorMessage("Please fill out all required fields.");
					setLoading(false);
					return;
				}
			}
		}

		try {
			const options = [];
			for (const [key, value] of Object.entries(customOptions)) {
				options.push({
					name: key,
					value: value,
				});
			}

			const cartItem = {
				sku: product.sku,
				quantity: quantity,
				options: options,
			};
			await addToCart(cartItem);

			clear();

			await populateCart();
			setCartDrawerVisible(true);
		} catch (error) {
			setErrorMessage("There was an error adding this item to your cart.");
		} finally {
			setLoading(false);
		}
	};

	const clear = () => {
		setCustomOptions({});
		setQuantity(1);
	};

	if (loading) {
		return <Loading />;
	}

	if (!loading && !product) {
		return <NotFound />;
	}

	return (
		<div>
			<Toast ref={toast} />
			<div className="md:px-8 py-2">
				<Link to="/products" className="text-blue-500 hover:text-blue-400 transition">
					<i className="pi pi-arrow-left mr-1"></i> Back to Shop
				</Link>
			</div>
			<div className="md:flex justify-between">
				<div className="md:w-1/2 md:p-8 pb-8 relative">
					<Image
						src={
							product.image ? product.image : "https://pdimage.petparty.co/hsapi/placeholder.jpg"
						}
						alt={product.name}
						className="w-full rounded-xl md:top-8"
						style={{ position: "sticky" }}
						preview
					/>
				</div>
				<div className="md:w-1/2 md:p-8">
					<h1 className="text-4xl font-semibold pb-1">{product.name}</h1>
					<p className="text-3xl mt-2 font-light pb-2">${product.price.toFixed(2)}</p>
					<Divider />
					{product.description && (
						<>
							<p className="font-light">{product.description}</p>
							<Divider />
						</>
					)}
					{product.is_custom &&
						Object.keys(product.custom_options)
							.sort((a, b) => {
								if (a === "background") {
									return -1;
								}
								if (b === "background") {
									return 1;
								}
								if (a === "designer_notes") {
									return 1;
								}
								if (b === "designer_notes") {
									return -1;
								}
								return 0;
							})
							.map((key, i) => {
								return (
									<div key={i}>
										{product.custom_options[key] ? (
											<>
												{product.custom_options[key].includes("uuid") && (
													<FileUploadInput
														customOptionKey={key}
														setCustomOptions={setCustomOptions}
														customOptions={customOptions}
														toast={toast}
														required={product.custom_options[key].includes("required")}
													/>
												)}

												{product.custom_options[key].includes("background") && (
													<BackgroundSelect
														customOptionKey={key}
														setCustomOptions={setCustomOptions}
														customOptions={customOptions}
														toast={toast}
														required={product.custom_options[key].includes("required")}
													/>
												)}

												{!product.custom_options[key].includes("uuid") &&
													!product.custom_options[key].includes("background") && (
														<div key={i}>
															<label className="my-2 font-semibold block">
																{(key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " ")}:{" "}
																{product.custom_options[key].includes("required") && (
																	<span className="text-red-500">*</span>
																)}
															</label>
															<InputTextarea
																rows={3}
																className="w-full"
																value={customOptions[key] ? customOptions[key] : ""}
																onInput={(e) => {
																	setCustomOptions({
																		...customOptions,
																		[key]: e.target.value,
																	});
																}}
															/>
														</div>
													)}
											</>
										) : (
											<div key={i}>
												<label className="my-2 font-semibold block">
													{(key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " ")}:
												</label>
												<InputTextarea
													rows={3}
													className="w-full"
													value={customOptions[key] ? customOptions[key] : ""}
													onInput={(e) => {
														setCustomOptions({
															...customOptions,
															[key]: e.target.value,
														});
													}}
												/>
											</div>
										)}
									</div>
								);
							})}

					<div className="w-full flex-col lg:flex-row items-stretch lg:items-end flex gap-2 justify-between">
						<div className="flex-col flex">
							<label htmlFor="quantity" className="font-semibold">
								Quantity:{" "}
							</label>
							<InputNumber
								className="grow"
								name="quantity"
								inputStyle={{ textAlign: "center" }}
								value={quantity}
								onValueChange={(e) => setQuantity(e.value)}
								showButtons
								buttonLayout="horizontal"
								step={1}
								min={1}
								decrementButtonClassName="p-button-secondary"
								incrementButtonClassName="p-button-secondary"
								incrementButtonIcon="pi pi-plus"
								decrementButtonIcon="pi pi-minus"
								size={4}
							/>
						</div>

						<Button
							className="justify-center grow"
							onClick={(event) => handleAddToCart(event, toast)}
							loading={loading}
							disabled={loading}>
							<span className="text-lg px-4 font-light lg:px-8">Add to Cart</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Product;
