import { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import NewDiscountDialog from "../../components/Admin/Discounts/New-Discount-Dialog";
import DeleteDiscountsDialog from "../../components/Admin/Discounts/Delete-Discounts-Dialog";
import EditDiscountDialog from "../../components/Admin/Discounts/Edit-Discount-Dialog";
import Loading from "../../components/Layout/Loading";

// Function imports
import { getDiscounts } from "../../api/admin/discounts";

const AdminDiscounts = () => {
	const [discounts, setDiscounts] = useState([]);
	const [search, setSearch] = useState("");
	const [selectedDiscounts, setSelectedDiscounts] = useState(null);
	const [loading, setLoading] = useState(false);

	const toast = useRef(null);

	useEffect(() => {
		populateDiscounts();
		document.title = "Discounts";
	}, []);

	const populateDiscounts = async () => {
		setLoading(true);
		try {
			const data = await getDiscounts();
			setDiscounts(data);
		} catch (err) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: err.message,
			});
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <Loading />;

	return (
		<div className="bg-white p-6 rounded-xl">
			<Toast ref={toast} className="opacity-100" />
			<DataTable
				value={discounts}
				sortMode="multiple"
				selection={selectedDiscounts}
				onSelectionChange={(e) => setSelectedDiscounts(e.value)}
				selectionMode="checkbox"
				dataKey="ID"
				responsiveLayout="scroll"
				filters={{
					global: { value: search, matchMode: FilterMatchMode.CONTAINS },
				}}
				header={
					<div className="w-full md:flex items-center justify-between">
						<h3 className="text-xl font-bold">Discounts</h3>

						<div className="flex items-center md:justify-end gap-2 flex-wrap">
							<NewDiscountDialog toast={toast} populateDiscounts={populateDiscounts} />
							<DeleteDiscountsDialog
								toast={toast}
								selectedDiscounts={selectedDiscounts}
								populateDiscounts={populateDiscounts}
							/>
							<span className="p-input-icon-left md:w-auto w-full">
								<i className="pi pi-search" />
								<InputText
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									placeholder="Search"
									className="p-inputtext-sm w-full"
								/>
							</span>
						</div>
					</div>
				}>
				<Column selectionMode="multiple" style={{ width: "3rem" }}></Column>
				<Column field="ID" header="ID" sortable></Column>
				<Column field="discount_code" header="Code" sortable></Column>
				<Column field="discount" header="Discount" sortable></Column>
				<Column
					body={(discount) => {
						return discount.discount_type.charAt(0).toUpperCase() + discount.discount_type.slice(1);
					}}
					header="Type"
					sortable></Column>
				<Column
					field="UpdatedAt"
					body={(discount) => {
						const date = new Date(discount.UpdatedAt);
						return date.toLocaleString();
					}}
					header="Updated"
					sortable></Column>
				<Column
					field="CreatedAt"
					body={(discount) => {
						const date = new Date(discount.CreatedAt);
						return date.toLocaleString();
					}}
					header="Created"
					sortable></Column>
				<Column
					body={(discount) => (
						<EditDiscountDialog
							discount={discount}
							toast={toast}
							populateDiscounts={populateDiscounts}
						/>
					)}
				/>
			</DataTable>
		</div>
	);
};

export default AdminDiscounts;
