import { useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";

import { uploadImage } from "../../../api/shop/upload-image";

const FileUploadInput = ({ customOptionKey, setCustomOptions, customOptions, toast, required }) => {
	const [loading, setLoading] = useState(false);

	const handleImageSelect = async (event) => {
		setLoading(true);
		try {
			const uuid = await uploadImage(event.files[0]);
			setCustomOptions({
				...customOptions,
				[customOptionKey]: uuid,
			});
		} catch (error) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: error.message,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<label className="my-2 font-semibold block">
				Upload{" "}
				{(customOptionKey.charAt(0).toUpperCase() + customOptionKey.slice(1)).replaceAll("_", " ")}:{" "}
				{required && <span className="text-red-500">*</span>}
			</label>
			<FileUpload
				name={customOptionKey}
				accept="image/*"
				onRemove={() => {
					setCustomOptions({
						...customOptions,
						[customOptionKey]: null,
					});
				}}
				headerTemplate={({ chooseButton }) => {
					return (
						<div className="p-fileupload-buttonbar flex items-center">
							{chooseButton}
							{loading && <ProgressSpinner style={{ height: "40px", margin: 0 }} />}
						</div>
					);
				}}
				itemTemplate={(file, props) => {
					return (
						<div className="flex items-center justify-between">
							<div className="flex items-center truncate pr-3">
								<img alt={file.name} role="presentation" src={file.objectURL} width={100} />
								<span className="flex flex-column text-left ml-3 truncate">{file.name}</span>
							</div>
							<Button
								type="button"
								icon="pi pi-times"
								className="p-button-outlined p-button-danger ml-auto"
								onClick={props.onRemove}
							/>
						</div>
					);
				}}
				progressBarTemplate={() => {
					return <></>;
				}}
				disabled={loading}
				onSelect={handleImageSelect}
			/>
		</div>
	);
};

export default FileUploadInput;
