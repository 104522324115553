import { Elements } from "@stripe/react-stripe-js";

import { useContext, useRef, useState, useEffect, useCallback } from "react";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { Link } from "react-router-dom";

import CartItem from "../../components/Layout/CartItem";
import Loading from "../../components/Layout/Loading";
import CheckoutForm from "../../components/Shop/Checkout-Form";

import CartContext from "../../context/cart";

import { loadStripe } from "@stripe/stripe-js";

import { getDiscount } from "../../api/shop/checkout";
import { useLayoutEffect } from "react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
	const { cart } = useContext(CartContext);
	const toast = useRef(null);
	const [loading, setLoading] = useState(false);
	const [discount, setDiscount] = useState({});
	const [discountCode, setDiscountCode] = useState("");
	const [subtotal, setSubtotal] = useState(0);
	const [shippingCost, setShippingCost] = useState(0);
	const [total, setTotal] = useState(0);

	// determine if this is first render to avoid turning loading off early
	const firstRender = useRef(true);
	useLayoutEffect(() => {
		firstRender.current = false;
	}, []);

	useEffect(() => {
		let subtotal = 0;
		cart.forEach((item) => {
			subtotal += item.price * item.quantity;
		});

		let shippingCost = 0;
		if (subtotal < 20) {
			shippingCost = 3.5;
		}

		let total = subtotal + shippingCost;
		if (discount.discount) {
			if (discount.discount_type === "percentage") {
				total = total - total * (discount.discount / 100);
			} else if (discount.discount_type === "fixed") {
				total = total - discount.discount;
			}
		}

		setSubtotal(subtotal <= 0 ? 0 : subtotal);
		setShippingCost(shippingCost <= 0 ? 0 : shippingCost);
		setTotal(total <= 0 ? 0 : total);
	}, [cart, discount]);

	const populateDiscount = useCallback(async () => {
		if (!firstRender.current) {
			setLoading(true);
		}

		if (!discountCode) {
			setDiscount({});
			return;
		}

		try {
			const discount = await getDiscount(discountCode);
			setDiscount(discount);

			// Set discount code in URL query params
			const urlParams = new URLSearchParams(window.location.search);
			urlParams.set("discount_code", discountCode);
			window.history.replaceState({}, "", `${window.location.pathname}?${urlParams.toString()}`);

			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: "Discount code applied.",
				life: 3000,
			});
		} catch (error) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: "Unable to apply discount code.",
				life: 3000,
			});
		}

		if (!firstRender.current) {
			setLoading(false);
		}
	}, [discountCode]);

	useEffect(() => {
		populateDiscount();
		document.title = "Checkout";
	}, [populateDiscount, discountCode]);

	useEffect(() => {
		// get discount code from URL query params
		const urlParams = new URLSearchParams(window.location.search);
		const discountCode = urlParams.get("discount_code");
		if (discountCode) {
			setDiscountCode(discountCode);
		}
	}, []);

	return (
		<div className="flex flex-col flex-1">
			<Toast ref={toast} />

			{cart.length < 1 && !loading ? (
				<Card className="flex-1 flex items-center justify-center">
					<div>
						<h1 className="text-2xl">Your cart is empty</h1>
						<Link to="/products" className="block mt-2">
							<Button label="Continue Shopping" className="w-full block" />
						</Link>
					</div>
				</Card>
			) : (
				<>
					<div className={`flex flex-1 items-center justify-center ${!loading && "hidden"}`}>
						<Loading />
					</div>

					<div
						className="flex-1 p-card lg:p-8 p-2 flex flex-col lg:flex-row-reverse lg:justify-around"
						style={loading ? { display: "none" } : {}}>
						<div
							className="lg:w-1/2 flex flex-col lg:px-2 justify-between"
							style={{ maxHeight: "70vh" }}>
							<Divider align="left" className="text-gray-500">
								Cart Items
							</Divider>
							<div className="overflow-auto flex-1">
								{cart.length > 0 &&
									cart
										.sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt))
										.map((item, i) => (
											<div className="w-full" key={i}>
												{i !== 0 && <Divider />}
												<CartItem item={item} toast={toast} />
											</div>
										))}
							</div>
							<Divider align="left" className="text-gray-500">
								Cart Totals
							</Divider>
							<div className="w-full">
								<p className="w-full flex items-center justify-between">
									<span className="text-gray-500 block">Subtotal:</span>{" "}
									<span className="font-semibold">
										{subtotal ? `$${subtotal.toFixed(2)}` : "FREE"}
									</span>
								</p>
								<p className="w-full flex items-center justify-between">
									<span className="text-gray-500 block">Shipping:</span>{" "}
									<span className="font-semibold">
										{shippingCost ? `$${shippingCost.toFixed(2)}` : "FREE"}
									</span>
								</p>
								{discount.discount && (
									<p className="w-full flex items-center justify-between">
										<span className="text-gray-500 block">Discount:</span>{" "}
										<span className="font-semibold">
											{discount.discount_type === "percentage"
												? `${discount.discount}% OFF`
												: `-$${
														subtotal + shippingCost < discount.discount
															? (subtotal + shippingCost).toFixed(2)
															: discount.discount.toFixed(2)
												  }`}
										</span>
									</p>
								)}
								<Divider />
								<p className="w-full flex items-center justify-between">
									<span className="text-gray-500 block">Total:</span>{" "}
									<span className="font-semibold">{total ? `$${total.toFixed(2)}` : "FREE"}</span>
								</p>
							</div>
						</div>
						<div className="hidden lg:block">
							<Divider layout="vertical" />
						</div>
						<div className="lg:hidden">
							<Divider />
						</div>
						<div className="lg:w-1/3">
							<Elements stripe={stripePromise}>
								<CheckoutForm
									setLoading={setLoading}
									toast={toast}
									discountCode={discountCode}
									setDiscountCode={setDiscountCode}
									total={total}
								/>
							</Elements>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Checkout;
