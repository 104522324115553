import { useState } from "react";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { putUpdateClient } from "../../../api/admin/clients";

const EditClientDialog = ({ client, populateClients, toast }) => {
	const [visible, setVisible] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

	const [name, setName] = useState(client.name);
	const [hqApiKey, setHqApiKey] = useState(client.hq_api_key);
	const [hqApiSecret, setHqApiSecret] = useState(client.hq_api_secret);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSubmitted(true);
		setLoading(true);

		if (!name || !hqApiKey || !hqApiSecret) {
			setLoading(false);
			return;
		}

		client.name = name;
		client.hq_api_key = hqApiKey;
		client.hq_api_secret = hqApiSecret;

		try {
			await putUpdateClient(client);
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: "Client updated successfully.",
			});

			populateClients();
			setVisible(false);
		} catch (err) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: err.message,
			});
		} finally {
			setLoading(false);
		}
	};

	const reset = () => {
		setName(client.name);
		setHqApiKey(client.hq_api_key);
		setHqApiSecret(client.hq_api_secret);
		setSubmitted(false);
	};

	return (
		<>
			<Button
				label="Edit"
				icon="pi pi-pencil"
				className="p-button-primary p-mr-2 p-button-sm"
				onClick={() => setVisible(true)}
			/>

			<Dialog
				header="Edit Client"
				visible={visible}
				className="w-4/5 md:w-1/3"
				modal
				onHide={() => {
					setVisible(false);
					reset();
				}}>
				<div className="my-2">
					<label htmlFor="name" className="block text-sm my-1">
						Name
					</label>
					<InputText
						id="name"
						required
						autoFocus
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={name}
						onInput={(e) => setName(e.target.value)}
					/>
					{submitted && !name && <small className="p-error">Name is required.</small>}
				</div>
				<div className="my-2">
					<label htmlFor="hqApiKey" className="block text-sm my-1">
						HQ API Key
					</label>
					<InputText
						id="hqApiKey"
						required
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={hqApiKey}
						onInput={(e) => setHqApiKey(e.target.value)}
					/>
					{submitted && !hqApiKey && <small className="p-error">HQ API Key is required.</small>}
				</div>
				<div className="my-2">
					<label htmlFor="hqApiSecret" className="block text-sm my-1">
						HQ API Secret
					</label>
					<InputText
						id="hqApiSecret"
						required
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={hqApiSecret}
						onInput={(e) => setHqApiSecret(e.target.value)}
					/>
					{submitted && !hqApiSecret && (
						<small className="p-error">HQ API Secret is required.</small>
					)}
				</div>
				<div className="flex justify-end mt-4">
					<Button
						label="Cancel"
						icon="pi pi-times"
						className="p-button-text"
						onClick={() => {
							reset();
							setVisible(false);
						}}
					/>
					<Button
						label="Save"
						icon="pi pi-check"
						className="p-button-text"
						onClick={handleSubmit}
						loading={loading}
						disabled={loading}
					/>
				</div>
			</Dialog>
		</>
	);
};

export default EditClientDialog;
