import React, { useState, useContext } from "react";
import UserContext from "../../context/user";
import CartContext from "../../context/cart";
import { Link } from "react-router-dom";

import { Sidebar } from "primereact/sidebar";
import { Badge } from "primereact/badge";

const MobileMenu = ({ logout }) => {
	const [isOpen, setIsOpen] = useState(false);
	const user = useContext(UserContext);
	const { cartDrawerVisible, setCartDrawerVisible, cart } = useContext(CartContext);

	return (
		<div className="md:hidden flex items-center justify-end gap-8">
			{!user.is_admin && (
				<i
					className="pi pi-shopping-cart block cursor-pointer hover:text-blue-500 transition p-overlay-badge"
					style={{ fontSize: "1.5rem" }}
					onClick={() => {
						setCartDrawerVisible(!cartDrawerVisible);
					}}>
					<Badge
						value={cart.reduce((acc, item) => acc + item.quantity, 0)}
						className="select-none"
					/>
				</i>
			)}

			<i
				className="pi pi-bars block cursor-pointer hover:text-blue-500 transition"
				onClick={() => {
					setIsOpen(!isOpen);
				}}></i>
			<Sidebar visible={isOpen} onHide={() => setIsOpen(false)} position="right">
				{!user.is_admin && (
					<Link
						to="/products"
						className="hover:text-blue-500 transition block px-2 py-1"
						onClick={() => {
							setIsOpen(false);
						}}>
						Shop
					</Link>
				)}

				<Link
					to={user.is_admin ? "/admin/orders" : "/orders"}
					className="hover:text-blue-500 transition block px-2 py-1"
					onClick={() => {
						setIsOpen(false);
					}}>
					Orders
				</Link>

				{user.is_admin && (
					<>
						<Link
							to="/admin/users"
							className="hover:text-blue-500 transition block px-2 py-1"
							onClick={() => {
								setIsOpen(false);
							}}>
							Users
						</Link>
						<Link
							to="/admin/clients"
							className="hover:text-blue-500 transition block px-2 py-1"
							onClick={() => {
								setIsOpen(false);
							}}>
							Clients
						</Link>
						<Link
							to="/admin/discounts"
							className="hover:text-blue-500 transition block px-2 py-1"
							onClick={() => {
								setIsOpen(false);
							}}>
							Discounts
						</Link>
					</>
				)}
				<button
					className="hover:text-blue-500 hover:bg-white border-blue-500 border p-1 px-4 rounded text-white flex items-center justify-center bg-blue-500 transition mx-2 my-1"
					onClick={logout}>
					Logout
				</button>
			</Sidebar>
		</div>
	);
};

export default MobileMenu;
