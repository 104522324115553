import { Card } from "primereact/card";
import { Link } from "react-router-dom";

const ProductGridItem = ({ product }) => {
	return (
		<Link to={`/product/${product.sku}`}>
			<Card key={product.ID} className="h-full hover:scale-105 transition">
				<div className="flex flex-col items-center justify-center gap-2">
					<img
						src={
							product.image ? product.image : "https://pdimage.petparty.co/hsapi/placeholder.jpg"
						}
						alt={product.name}
					/>
					<div className="text-center">
						<h3 className="text-xl font-bold">{product.name}</h3>
					</div>
				</div>
			</Card>
		</Link>
	);
};

export default ProductGridItem;
