import { getBearerToken } from "../auth";

const uploadImage = async (file) => {
	const formData = new FormData();
	formData.append("image", file, file.name);

	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/upload-image`, {
		method: "POST",
		headers: {
			Authorization: getBearerToken(),
		},
		body: formData,
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const data = await response.json();
	return data.uuid;
};

export { uploadImage };
