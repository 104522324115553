import { getBearerToken } from "../auth";

const getDiscounts = async () => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/discounts`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const discounts = await response.json();
	return discounts;
};

const postCreateDiscount = async (discount) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/discount`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify(discount),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const createdDiscount = await response.json();
	return createdDiscount;
};

const putUpdateDiscount = async (discount) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/discount`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify(discount),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const updatedDiscount = await response.json();
	return updatedDiscount;
};

const deleteDiscounts = async (discount_ids) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/discounts`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify({ discount_ids: discount_ids }),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	return;
};

export { getDiscounts, postCreateDiscount, deleteDiscounts, putUpdateDiscount };
