import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

// Component Imports
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

// Function Imports
import { getJWTAuthorizationToken } from "../../api/auth";

const Login = () => {
	const navigate = useNavigate();

	const [formLoading, setFormLoading] = useState(false);
	const [formError, setFormError] = useState("");

	useEffect(() => {
		document.title = "Login";
	}, []);

	// Submit Login Form
	const submitLoginForm = async (e) => {
		e.preventDefault();
		setFormLoading(true);

		try {
			const username_or_email = e.target.username_or_email.value;
			if (username_or_email === "") throw new Error("Username or Email is required");

			const password = e.target.password.value;
			if (password === "") throw new Error("Password is required");

			const jwt_token = await getJWTAuthorizationToken(username_or_email, password);
			Cookies.set("jwt_authorization", jwt_token, {
				expires: 1,
			});

			setFormError("");
			navigateAfterLogin();
		} catch (error) {
			setFormError(error.message);
		}

		setFormLoading(false);
	};

	const navigateAfterLogin = () => {
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.get("redirect_url") ? navigate(searchParams.get("redirect_url")) : navigate("/");
	};

	return (
		<div className="bg-gray-100 h-screen w-screen flex items-center justify-center">
			<Card className="bg-white w-screen md:max-w-lg h-screen md:h-auto">
				<img
					src="https://cdn.shopify.com/s/files/1/0440/5285/files/hs-logo1_120x.png?v=1617341324"
					alt=""
					className="mx-auto mb-8 "
				/>

				{/* form error */}
				{formError && (
					<div
						className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
						role="alert">
						<span className="block sm:inline">{formError}</span>
					</div>
				)}

				<form onSubmit={submitLoginForm}>
					<div className="w-full mt-4">
						<label htmlFor="username_or_email" className="text-black block mb-1">
							Username or Email Address
						</label>
						<InputText
							name="username_or_email"
							id="username_or_email"
							className="w-full"
							disabled={formLoading}
						/>
					</div>
					<div className="w-full mt-4">
						<label htmlFor="password" className="text-black block mb-1">
							Password
						</label>
						<Password
							name="password"
							id="password"
							className="w-full"
							inputClassName="w-full"
							toggleMask={true}
							feedback={false}
							disabled={formLoading}
						/>
					</div>
					<div className="w-full mt-2">
						<Link to="/forgot-password" className="text-blue-700 hover:text-blue-600 transition">
							Forgot Password?
						</Link>
					</div>

					<div className="w-full mt-4">
						<Button label="Login" className="w-full" loading={formLoading} />
					</div>
				</form>
			</Card>
		</div>
	);
};

export default Login;
