import { getBearerToken } from "../auth";

const getUsers = async () => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/users`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const users = await response.json();
	return users;
};

const postCreateUser = async (user) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/user`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify(user),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const createdUser = await response.json();
	return createdUser;
};

const putUpdateUser = async (user) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/user`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify(user),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const updatedUser = await response.json();
	return updatedUser;
};

const deleteUsers = async (user_ids) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/users`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify({ user_ids: user_ids }),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	return;
};

export { getUsers, postCreateUser, deleteUsers, putUpdateUser };
