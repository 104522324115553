import { useEffect } from "react";
import { Link } from "react-router-dom";

import { Card } from "primereact/card";

const ResetPasswordSuccess = () => {
	useEffect(() => {
		document.title = "Reset Password Success";
	}, []);

	const getIsFirstPasswordSetFromURLQuery = () => {
		const searchParams = new URLSearchParams(window.location.search);
		return searchParams.get("first");
	};

	return (
		<div className="bg-gray-100 h-screen w-screen flex items-center justify-center">
			<Card className="bg-white w-screen md:max-w-lg h-screen md:h-auto">
				<img
					src="https://cdn.shopify.com/s/files/1/0440/5285/files/hs-logo1_120x.png?v=1617341324"
					alt=""
					className="mx-auto mb-8 "
				/>
				<div className="text-center">
					<h1 className="text-2xl font-bold">
						Password {getIsFirstPasswordSetFromURLQuery() === "true" ? "Set" : "Reset"} Successfully
					</h1>
					<p className="text-gray-600">You can now login with your new password.</p>
					<Link to="/login" className="text-blue-500 mt-4 block">
						{getIsFirstPasswordSetFromURLQuery() === "true" ? "Go" : "Back"} to Login
					</Link>
				</div>
			</Card>
		</div>
	);
};

export default ResetPasswordSuccess;
