import { Link } from "react-router-dom";
import Menu from "./Menu";
import MobileMenu from "./Mobile-Menu";

const Header = ({ cartDrawerVisible, setCartDrawerVisible, logout }) => {
	return (
		<header className="w-full border-b border-gray-200 p-4 md:px-20 md:py-6 flex items-center justify-between bg-white">
			<Link to="/">
				<img
					src="https://cdn.shopify.com/s/files/1/0440/5285/files/hs-logo1_120x.png?v=1617341324"
					alt=""
				/>
			</Link>
			<Menu
				logout={logout}
				cartDrawerVisible={cartDrawerVisible}
				setCartDrawerVisible={setCartDrawerVisible}
			/>
			<MobileMenu
				logout={logout}
				cartDrawerVisible={cartDrawerVisible}
				setCartDrawerVisible={setCartDrawerVisible}
			/>
		</header>
	);
};

export default Header;
