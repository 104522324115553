import { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import NewClientDialog from "../../components/Admin/Clients/New-Client-Dialog";
import DeleteClientsDialog from "../../components/Admin/Clients/Delete-Clients-Dialog";
import EditClientDialog from "../../components/Admin/Clients/Edit-Client-Dialog";
import Loading from "../../components/Layout/Loading";

// Function imports
import { getClients } from "../../api/admin/clients";

const AdminClients = () => {
	const [clients, setClients] = useState([]);
	const [search, setSearch] = useState("");
	const [selectedClients, setSelectedClients] = useState(null);
	const [loading, setLoading] = useState(false);

	const toast = useRef(null);

	useEffect(() => {
		populateClients();
		document.title = "Clients";
	}, []);

	const populateClients = async () => {
		setLoading(true);
		try {
			const data = await getClients();
			setClients(data);
		} catch (err) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: err.message,
			});
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <Loading />;

	return (
		<div className="bg-white p-6 rounded-xl">
			<Toast ref={toast} className="opacity-100" />
			<DataTable
				value={clients}
				sortMode="multiple"
				selection={selectedClients}
				onSelectionChange={(e) => setSelectedClients(e.value)}
				selectionMode="checkbox"
				dataKey="ID"
				responsiveLayout="scroll"
				filters={{
					global: { value: search, matchMode: FilterMatchMode.CONTAINS },
				}}
				header={
					<div className="w-full md:flex items-center justify-between">
						<h3 className="text-xl font-bold">Clients</h3>

						<div className="flex items-center md:justify-end gap-2 flex-wrap">
							<NewClientDialog toast={toast} populateClients={populateClients} />
							<DeleteClientsDialog
								toast={toast}
								selectedClients={selectedClients}
								populateClients={populateClients}
							/>
							<span className="p-input-icon-left md:w-auto w-full">
								<i className="pi pi-search" />
								<InputText
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									placeholder="Search"
									className="p-inputtext-sm w-full"
								/>
							</span>
						</div>
					</div>
				}>
				<Column selectionMode="multiple" style={{ width: "3rem" }}></Column>
				<Column field="ID" header="ID" sortable></Column>
				<Column field="name" header="Name" sortable></Column>
				<Column
					field="UpdatedAt"
					body={(client) => {
						const date = new Date(client.UpdatedAt);
						return date.toLocaleString();
					}}
					header="Updated"
					sortable></Column>
				<Column
					body={(client) => {
						const date = new Date(client.CreatedAt);
						return date.toLocaleString();
					}}
					header="Created"
					sortable></Column>
				<Column
					body={(client) => (
						<EditClientDialog client={client} toast={toast} populateClients={populateClients} />
					)}
				/>
			</DataTable>
		</div>
	);
};

export default AdminClients;
