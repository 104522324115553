import { getBearerToken } from "../auth";

const getProducts = async () => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/products`, {
		method: "GET",
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: getBearerToken(),
		},
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const products = await response.json();
	return products;
};

const getProduct = async (sku) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/product/${sku}`, {
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const product = await response.json();
	return product;
};

const getBackgrounds = async () => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/backgrounds`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const backgrounds = await response.json();
	return backgrounds;
};

export { getProducts, getProduct, getBackgrounds };
