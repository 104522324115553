import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { useEffect, useCallback, useState, useContext } from "react";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { Checkbox } from "primereact/checkbox";

import { useNavigate } from "react-router-dom";

import { getSavedCreditCards, completeCheckout } from "../../api/shop/checkout";

import CartContext from "../../context/cart";

const states = [
	"AL",
	"AK",
	"AZ",
	"AR",
	"CA",
	"CO",
	"CT",
	"DE",
	"FL",
	"GA",
	"HI",
	"ID",
	"IL",
	"IN",
	"IA",
	"KS",
	"KY",
	"LA",
	"ME",
	"MD",
	"MA",
	"MI",
	"MN",
	"MS",
	"MO",
	"MT",
	"NE",
	"NV",
	"NH",
	"NJ",
	"NM",
	"NY",
	"NC",
	"ND",
	"OH",
	"OK",
	"OR",
	"PA",
	"RI",
	"SC",
	"SD",
	"TN",
	"TX",
	"UT",
	"VT",
	"VA",
	"WA",
	"WV",
	"WI",
	"WY",
];

const CheckoutForm = ({ setLoading, toast, discountCode, setDiscountCode, total }) => {
	const stripe = useStripe();
	const elements = useElements();

	const { populateCart } = useContext(CartContext);

	const [shippingAddress, setShippingAddress] = useState({
		first_name: "",
		last_name: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zip: "",
	});
	const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
	const [submitted, setSubmitted] = useState(false);
	const [useNewPaymentMethod, setUseNewPaymentMethod] = useState(false);
	const [saveNewPaymentMethod, setSaveNewPaymentMethod] = useState(false);
	const [discountCodeInputValue, setDiscountCodeInputValue] = useState("");

	const navigate = useNavigate();

	const populateCheckout = useCallback(async () => {
		setLoading(true);

		try {
			const savedPaymentMethods = await getSavedCreditCards();
			setSavedPaymentMethods(savedPaymentMethods);

			if (savedPaymentMethods.length < 1) {
				setUseNewPaymentMethod(true);
			}
		} catch (error) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: error.message,
				life: 3000,
			});
		} finally {
			setLoading(false);
		}
	}, [setLoading, toast]);

	useEffect(() => {
		populateCheckout();
	}, [populateCheckout]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setSubmitted(true);

		if (total <= 0) {
			try {
				const order = await completeCheckout({
					shipping_address: shippingAddress,
					discount_code: discountCode,
				});

				await populateCart();
				navigate("/checkout/success?order_number=" + order.order_number);
				return;
			} catch (error) {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				setLoading(false);
				return;
			} finally {
				setLoading(false);
			}
		}

		if (
			!shippingAddress.first_name ||
			!shippingAddress.last_name ||
			!shippingAddress.address1 ||
			!shippingAddress.city ||
			!shippingAddress.state ||
			!shippingAddress.zip
		) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: "Please fill out all required fields",
				life: 3000,
			});
			setLoading(false);
			return;
		}

		if (!useNewPaymentMethod && !selectedPaymentMethod.id) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: "Please select a payment method",
				life: 3000,
			});
			setLoading(false);
			return;
		}

		let paymentMethodId = selectedPaymentMethod?.id;

		if (useNewPaymentMethod) {
			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardElement),
			});

			if (error) {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: error.message,
					life: 3000,
				});
				setLoading(false);
				return;
			}

			paymentMethodId = paymentMethod.id;
		}

		try {
			const order = await completeCheckout({
				shipping_address: shippingAddress,
				payment_method_id: paymentMethodId,
				save_payment_method: saveNewPaymentMethod && useNewPaymentMethod,
				discount_code: discountCode,
			});

			await populateCart();
			navigate("/checkout/success?order_number=" + order.order_number);
		} catch (error) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: error.message,
				life: 3000,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleApplyDiscountCode = async () => {
		setDiscountCode(discountCodeInputValue);
		setDiscountCodeInputValue("");
	};

	return (
		<>
			<h1 className="text-xl font-semibold text-center">Billing Details</h1>
			<Divider align="left">
				<span className="text-gray-500">Shipping Address</span>
			</Divider>
			<div className="flex flex-col gap-2">
				<div className="flex flex-col lg:flex-row gap-2">
					<div className="lg:w-1/2">
						<InputText
							className={`w-full ${submitted && !shippingAddress.first_name && "p-invalid"}`}
							placeholder="First Name"
							value={shippingAddress.first_name}
							onChange={(e) =>
								setShippingAddress({ ...shippingAddress, first_name: e.target.value })
							}
						/>
					</div>
					<div className="lg:w-1/2">
						<InputText
							placeholder="Last Name"
							className={`w-full ${submitted && !shippingAddress.last_name && "p-invalid"}`}
							value={shippingAddress.last_name}
							onChange={(e) =>
								setShippingAddress({ ...shippingAddress, last_name: e.target.value })
							}
						/>
					</div>
				</div>

				<div className="w-full">
					<InputText
						placeholder="Address Line 1"
						className={`w-full ${submitted && !shippingAddress.address1 && "p-invalid"}`}
						value={shippingAddress.address1}
						onChange={(e) => setShippingAddress({ ...shippingAddress, address1: e.target.value })}
					/>
				</div>
				<div className="w-full">
					<InputText
						placeholder="Address Line 2"
						className="w-full"
						value={shippingAddress.address2}
						onChange={(e) => setShippingAddress({ ...shippingAddress, address2: e.target.value })}
					/>
				</div>
				<div className="w-full">
					<InputText
						placeholder="City"
						className={`w-full ${submitted && !shippingAddress.city && "p-invalid"}`}
						value={shippingAddress.city}
						onChange={(e) => setShippingAddress({ ...shippingAddress, city: e.target.value })}
					/>
				</div>

				<div className="flex flex-col lg:flex-row gap-2">
					<div className="lg:w-1/2">
						<InputMask
							placeholder="Zip Code"
							className={`w-full ${submitted && !shippingAddress.zip && "p-invalid"}`}
							value={shippingAddress.zip}
							mask="99999"
							slotChar=""
							onChange={(e) => setShippingAddress({ ...shippingAddress, zip: e.target.value })}
						/>
					</div>
					<div className="lg:w-1/2">
						<Dropdown
							className={`w-full ${submitted && !shippingAddress.state && "p-invalid"}`}
							value={shippingAddress.state}
							options={states}
							placeholder="Select a State"
							showClear
							onChange={(e) => setShippingAddress({ ...shippingAddress, state: e.value })}
						/>
					</div>
				</div>
			</div>
			<div>
				<Divider align="left">
					<span className="text-gray-500">Payment Method</span>
				</Divider>
				<div className="flex flex-col gap-2">
					<div className="p-inputgroup">
						<InputText
							placeholder="Discount Code"
							className="w-full"
							value={discountCodeInputValue}
							onChange={(e) => setDiscountCodeInputValue(e.target.value)}
						/>
						<Button
							label="Apply"
							className="p-button-secondary"
							disabled={discountCodeInputValue === ""}
							onClick={handleApplyDiscountCode}
						/>
					</div>
					<div className={`flex flex-col gap-2 ${total <= 0 && "hidden"}`}>
						<div className={`w-full ${savedPaymentMethods.length < 1 && "hidden"}`}>
							<Dropdown
								className={`w-full ${
									submitted && !useNewPaymentMethod && !selectedPaymentMethod?.id && "p-invalid"
								}`}
								value={selectedPaymentMethod}
								options={savedPaymentMethods}
								placeholder="Select a Card"
								itemTemplate={(option, props) => {
									if (!option) {
										return <span>{props.placeholder}</span>;
									}

									console.log(option);
									return (
										<div className="flex items-center justify-between">
											<span className="block">
												{option.card.brand.charAt(0).toUpperCase() + option.card.brand.slice(1)}{" "}
												ending in {option.card.last4}
											</span>
											<span className="block">
												Expires {option.card.exp_month}/{option.card.exp_year}
											</span>
										</div>
									);
								}}
								valueTemplate={(option, props) => {
									if (!option) {
										return <span>{props.placeholder}</span>;
									}

									return (
										<div className="flex items-center justify-between">
											<span className="block">
												{option.card.brand.charAt(0).toUpperCase() + option.card.brand.slice(1)}{" "}
												ending in {option.card.last4}
											</span>
											<span className="block">
												Expires {option.card.exp_month}/{option.card.exp_year}
											</span>
										</div>
									);
								}}
								optionLabel="card.last4"
								showClear
								onChange={(e) => setSelectedPaymentMethod(e.value)}
							/>
						</div>
						<div className={`w-full ${savedPaymentMethods.length < 1 && "hidden"}`}>
							<Checkbox
								inputId="useNewPaymentMethod"
								checked={useNewPaymentMethod}
								onChange={(e) => setUseNewPaymentMethod(e.checked)}
							/>
							<label htmlFor="useNewPaymentMethod" className="ml-2 p-checkbox-label">
								Use New Payment Method
							</label>
						</div>
						<div
							className={`w-full ${
								savedPaymentMethods.length > 0 && !useNewPaymentMethod && "hidden"
							}`}>
							<CardElement className="p-inputtext" />
							<div className="w-full mt-1">
								<Checkbox
									inputId="saveNewPaymentMethod"
									checked={saveNewPaymentMethod}
									onChange={(e) => setSaveNewPaymentMethod(e.checked)}
								/>
								<label htmlFor="saveNewPaymentMethod" className="ml-2 p-checkbox-label">
									Save New Payment Method
								</label>
							</div>
						</div>
					</div>

					<Button label="Place Order" className="w-full" onClick={handleSubmit} />
				</div>
			</div>
		</>
	);
};

export default CheckoutForm;
