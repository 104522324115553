import { useState } from "react";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { postCreateClient } from "../../../api/admin/clients";

const NewClientDialog = ({ toast, populateClients }) => {
	const [visible, setVisible] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

	const [name, setName] = useState("");
	const [hqApiKey, setHqApiKey] = useState("");
	const [hqApiSecret, setHqApiSecret] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSubmitted(true);
		setLoading(true);

		if (!name || !hqApiKey || !hqApiSecret) {
			setLoading(false);
			return;
		}

		try {
			await postCreateClient({ name: name, hq_api_key: hqApiKey, hq_api_secret: hqApiSecret });
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: "Client created successfully.",
			});

			populateClients();
			clear();
			setVisible(false);
		} catch (err) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: err.message,
			});
		} finally {
			setLoading(false);
		}
	};

	const clear = () => {
		setName("");
		setHqApiKey("");
		setHqApiSecret("");
		setSubmitted(false);
	};

	return (
		<>
			<Button
				label="Add Client"
				className="p-button-sm p-button-success"
				icon="pi pi-plus"
				onClick={() => {
					setVisible(true);
				}}
			/>
			<Dialog
				header="Add Client"
				visible={visible}
				className="w-4/5 md:w-1/3"
				modal
				onHide={() => {
					setVisible(false);
					clear();
				}}>
				<div className="my-2">
					<label htmlFor="name" className="block text-sm my-1">
						Name
					</label>
					<InputText
						id="name"
						required
						autoFocus
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={name}
						onInput={(e) => setName(e.target.value)}
					/>
					{submitted && !name && <small className="p-error">Name is required.</small>}
				</div>
				<div className="my-2">
					<label htmlFor="hqApiKey" className="block text-sm my-1">
						HQ API Key
					</label>
					<InputText
						id="hqApiKey"
						required
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={hqApiKey}
						onInput={(e) => setHqApiKey(e.target.value)}
					/>
					{submitted && !hqApiKey && <small className="p-error">HQ API Key is required.</small>}
				</div>
				<div className="my-2">
					<label htmlFor="hqApiSecret" className="block text-sm my-1">
						HQ API Secret
					</label>
					<InputText
						id="hqApiSecret"
						required
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={hqApiSecret}
						onInput={(e) => setHqApiSecret(e.target.value)}
					/>
					{submitted && !hqApiSecret && (
						<small className="p-error">HQ API Secret is required.</small>
					)}
				</div>
				<div className="flex justify-end mt-4">
					<Button
						label="Cancel"
						icon="pi pi-times"
						className="p-button-text"
						onClick={() => {
							clear();
							setVisible(false);
						}}
					/>
					<Button
						label="Add"
						icon="pi pi-check"
						className="p-button-text"
						onClick={handleSubmit}
						loading={loading}
						disabled={loading}
					/>
				</div>
			</Dialog>
		</>
	);
};

export default NewClientDialog;
