import { useEffect } from "react";
import { Link } from "react-router-dom";

import { Card } from "primereact/card";

const ForgotPasswordSent = () => {
	useEffect(() => {
		document.title = "Forgot Password Email Sent";
	}, []);

	return (
		<div className="bg-gray-100 h-screen w-screen flex items-center justify-center">
			<Card className="bg-white w-screen md:max-w-lg h-screen md:h-auto">
				<img
					src="https://cdn.shopify.com/s/files/1/0440/5285/files/hs-logo1_120x.png?v=1617341324"
					alt=""
					className="mx-auto mb-8 "
				/>
				<div className="text-center">
					<h1 className="text-2xl font-bold">Email Sent Successfully</h1>
					<p className="text-gray-600 mt-1">
						Please check your email for a link to reset your password.
					</p>
					<Link to="/login" className="text-blue-500 mt-4 block">
						Back to Login
					</Link>
				</div>
			</Card>
		</div>
	);
};

export default ForgotPasswordSent;
