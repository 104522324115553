import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Component Imports
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

// Function Imports
import { sendForgotPasswordEmail } from "../../api/auth";

const ForgotPassword = () => {
	const navigate = useNavigate();

	const [formLoading, setFormLoading] = useState(false);
	const [formError, setFormError] = useState("");

	useEffect(() => {
		document.title = "Forgot Password";
	}, []);

	const submitForgotPasswordForm = async (e) => {
		e.preventDefault();
		setFormLoading(true);

		try {
			const email = e.target.email.value;
			if (email === "") throw new Error("Email is required");

			await sendForgotPasswordEmail(email);

			setFormError("");
			navigateAfterEmailSent();
		} catch (error) {
			setFormError(error.message);
		}

		setFormLoading(false);
	};

	const navigateAfterEmailSent = () => {
		navigate("/forgot-password/sent");
	};

	return (
		<div className="bg-gray-100 h-screen w-screen flex items-center justify-center">
			<Card className="bg-white w-screen md:max-w-lg h-screen md:h-auto">
				<img
					src="https://cdn.shopify.com/s/files/1/0440/5285/files/hs-logo1_120x.png?v=1617341324"
					alt=""
					className="mx-auto mb-8 "
				/>

				{/* form error */}
				{formError && (
					<div
						className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
						role="alert">
						<span className="block sm:inline">{formError}</span>
					</div>
				)}

				<form onSubmit={submitForgotPasswordForm}>
					<div className="w-full mt-4">
						<label htmlFor="email" className="text-black block mb-1">
							Email Address
						</label>
						<InputText name="email" id="email" className="w-full" disabled={formLoading} />
					</div>
					<div className="w-full mt-2">
						<Link to="/login" className="text-blue-700 hover:text-blue-600 transition">
							Back to Login
						</Link>
					</div>

					<div className="w-full mt-4">
						<Button label="Send Link to Reset Password" className="w-full" loading={formLoading} />
					</div>
				</form>
			</Card>
		</div>
	);
};

export default ForgotPassword;
