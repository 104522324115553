import { useState } from "react";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

import { putUpdateDiscount } from "../../../api/admin/discounts";

const EditDiscountDialog = ({ discount, populateDiscounts, toast }) => {
	const [visible, setVisible] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

	const [discountCode, setDiscountCode] = useState(discount.discount_code);
	const [discountAmount, setDiscountAmount] = useState(discount.discount);
	const [discountType, setDiscountType] = useState(discount.discount_type);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSubmitted(true);
		setLoading(true);

		if (!discountCode || !discountAmount || !discountType) {
			setLoading(false);
			return;
		}

		discount.discount_code = discountCode;
		discount.discount = discountAmount;
		discount.discount_type = discountType;

		try {
			await putUpdateDiscount(discount);
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: "Discount updated successfully.",
			});

			populateDiscounts();
			setVisible(false);
		} catch (err) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: err.message,
			});
		} finally {
			setLoading(false);
		}
	};

	const reset = () => {
		setDiscountCode(discount.discount_code);
		setDiscountAmount(discount.discount);
		setDiscountType(discount.discount_type);
		setSubmitted(false);
	};

	return (
		<>
			<Button
				label="Edit"
				icon="pi pi-pencil"
				className="p-button-primary p-mr-2 p-button-sm"
				onClick={() => setVisible(true)}
			/>

			<Dialog
				header="Edit Discount"
				visible={visible}
				className="w-4/5 md:w-1/3"
				modal
				onHide={() => {
					setVisible(false);
					reset();
				}}>
				<div className="my-2">
					<label htmlFor="discount_code" className="block text-sm my-1">
						Discount Code
					</label>
					<InputText
						id="discount_code"
						required
						autoFocus
						autoComplete="off"
						className="block w-full p-inputtext-sm"
						value={discountCode}
						onInput={(e) => setDiscountCode(e.target.value)}
					/>
					{submitted && !discountCode && (
						<small className="p-error">Discount Code is required.</small>
					)}
				</div>
				<div className="my-2">
					<label htmlFor="discount" className="block text-sm my-1">
						Discount Amount
					</label>
					<InputNumber
						id="discount"
						required
						className="block w-full p-inputtext-sm"
						value={discountAmount}
						min={1}
						max={100}
						onValueChange={(e) => setDiscountAmount(e.value)}
					/>
					{submitted && !discountAmount && (
						<small className="p-error">Discount Amount is required.</small>
					)}
				</div>
				<div className="my-2">
					<label htmlFor="discountType" className="block text-sm my-1">
						Discount Type
					</label>
					<Dropdown
						id="discountType"
						required
						className="block w-full p-inputtext-sm"
						value={discountType}
						options={[
							{ label: "Percentage", value: "percentage" },
							{ label: "Fixed", value: "fixed" },
						]}
						onChange={(e) => setDiscountType(e.value)}
					/>
					{submitted && !discountType && (
						<small className="p-error">Discount Type is required.</small>
					)}
				</div>
				<div className="flex justify-end mt-4">
					<Button
						label="Cancel"
						icon="pi pi-times"
						className="p-button-text"
						onClick={() => {
							reset();
							setVisible(false);
						}}
					/>
					<Button
						label="Save"
						icon="pi pi-check"
						className="p-button-text"
						onClick={handleSubmit}
						loading={loading}
						disabled={loading}
					/>
				</div>
			</Dialog>
		</>
	);
};

export default EditDiscountDialog;
