import { getBearerToken } from "../auth";

const getClients = async () => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/clients`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const clients = await response.json();
	return clients;
};

const postCreateClient = async (client) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/client`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify(client),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const createdClient = await response.json();
	return createdClient;
};

const deleteClients = async (client_ids) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/clients`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify({ client_ids: client_ids }),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	return;
};

const putUpdateClient = async (client) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/client`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify(client),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const updatedClient = await response.json();
	return updatedClient;
};

export { getClients, postCreateClient, deleteClients, putUpdateClient };
