import { useState } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { deleteDiscounts } from "../../../api/admin/discounts";

const DeleteDiscountsDialog = ({ toast, selectedDiscounts, populateDiscounts }) => {
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleDelete = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const discount_ids = selectedDiscounts.map((discount) => discount.ID);
			await deleteDiscounts(discount_ids);
			populateDiscounts();
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: "Discounts Deleted Successfully.",
			});
			setVisible(false);
		} catch (err) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: err.message,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Button
				label="Delete"
				className="p-button-sm p-button-danger"
				icon="pi pi-trash"
				onClick={() => {
					setVisible(true);
				}}
				disabled={!selectedDiscounts || selectedDiscounts.length === 0}
			/>
			<Dialog
				header="Confirm Delete Selected Discounts"
				visible={visible}
				className="w-4/5 md:w-1/3"
				modal
				onHide={() => {
					setVisible(false);
				}}>
				<div className="py-2 px-1">
					<i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
					<span>Are you sure you want to delete the selected discounts?</span>
				</div>

				<div className="mt-2 flex items-center justify-end">
					<Button
						label="No"
						icon="pi pi-times"
						className="p-button-text"
						onClick={() => {
							setVisible(false);
						}}
						loading={loading}
						disabled={loading}
					/>
					<Button
						label="Yes"
						icon="pi pi-check"
						className="p-button-text"
						onClick={(e) => {
							handleDelete(e);
						}}
						loading={loading}
						disabled={loading}
					/>
				</div>
			</Dialog>
		</>
	);
};

export default DeleteDiscountsDialog;
