import { Link } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../context/user";
import CartContext from "../../context/cart";

import { Badge } from "primereact/badge";

const Menu = ({ logout }) => {
	const user = useContext(UserContext);
	const { cartDrawerVisible, setCartDrawerVisible, cart } = useContext(CartContext);

	return (
		<div className="hidden md:flex items-center justify-end gap-8">
			{!user.is_admin && (
				<Link to="/products" className="hover:text-blue-500 transition">
					Shop
				</Link>
			)}
			<Link
				to={user.is_admin ? "/admin/orders" : "/orders"}
				className="hover:text-blue-500 transition">
				Orders
			</Link>
			{!user.is_admin && (
				<i
					className="pi pi-shopping-cart block cursor-pointer hover:text-blue-500 transition p-overlay-badge"
					style={{ fontSize: "1.5rem" }}
					onClick={() => {
						setCartDrawerVisible(!cartDrawerVisible);
					}}>
					<Badge
						value={cart.reduce((acc, item) => acc + item.quantity, 0)}
						className="select-none"
					/>
				</i>
			)}

			{user.is_admin && (
				<>
					<Link to="/admin/users" className="hover:text-blue-500 transition">
						Users
					</Link>
					<Link to="/admin/clients" className="hover:text-blue-500 transition">
						Clients
					</Link>
					<Link to="/admin/discounts" className="hover:text-blue-500 transition">
						Discounts
					</Link>
				</>
			)}
			<button
				className="hover:text-blue-500 hover:bg-white border-blue-500 border p-1 px-4 rounded text-white flex items-center justify-center bg-blue-500 transition"
				onClick={logout}>
				Logout
			</button>
		</div>
	);
};

export default Menu;
