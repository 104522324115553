import { Sidebar } from "primereact/sidebar";
import { Divider } from "primereact/divider";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { Link } from "react-router-dom";

import { useContext, useRef } from "react";
import CartContext from "../../context/cart";
import CartItem from "./CartItem";
import Loading from "./Loading";

const CartDrawer = () => {
	const { cartDrawerVisible, setCartDrawerVisible, cart, cartLoading } = useContext(CartContext);
	const toast = useRef(null);

	return (
		<>
			<Toast ref={toast} />
			<Sidebar
				style={{ width: "475px", maxWidth: "100%" }}
				visible={cartDrawerVisible}
				position="right"
				onHide={() => setCartDrawerVisible(false)}>
				<>
					<Loading hidden={!cartLoading} />
					<div className={`h-full flex flex-col ${cartLoading && "hidden"}`}>
						<div className="p-4">
							<h1 className="text-2xl font-semibold text-center">
								Your Cart{" "}
								{cart.length > 0 && (
									<span>({cart.reduce((acc, item) => acc + item.quantity, 0)})</span>
								)}
							</h1>
						</div>
						<Divider />
						<ScrollPanel className="flex-1 overflow-auto">
							{cart.length > 0 ? (
								cart
									.sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt))
									.map((item, i) => (
										<div className="w-full" key={i}>
											{i !== 0 && <Divider />}
											<CartItem item={item} toast={toast} />
										</div>
									))
							) : (
								<div className="p-4">
									<h1 className="text-xl text-gray-500 font-light text-center">
										Your cart is empty
									</h1>
								</div>
							)}
						</ScrollPanel>
						<Divider />
						<div className="p-4">
							<h1 className="text-xl font-semibold mb-4">
								Total: ${cart.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2)}
							</h1>
							<Link className="w-full" to="/checkout">
								<Button
									className="w-full"
									label="Checkout"
									onClick={() => {
										setCartDrawerVisible(false);
									}}
								/>
							</Link>
						</div>
					</div>
				</>
			</Sidebar>
		</>
	);
};

export default CartDrawer;
