import { useState, useEffect, useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import UserContext from "../../context/user";
import CartContext from "../../context/cart";
import jwt from "jwt-decode";

import { getCart, addToCart, updateCart } from "../../api/shop/cart";

// import components
import CartDrawer from "./CartDrawer";
import Loading from "./Loading";
import Header from "./Header";

const Layout = ({ children }) => {
	const navigate = useNavigate();
	const [cartDrawerVisible, setCartDrawerVisible] = useState(false);
	const [user, setUser] = useState(null);
	const [cart, setCart] = useState([]);
	const [cartLoading, setCartLoading] = useState(false);

	const logout = () => {
		Cookies.remove("jwt_authorization");
		navigate("/login");
	};

	const getUserCookie = () => {
		const userCookie = Cookies.get("jwt_authorization");
		return userCookie;
	};

	const parseUserCookie = (userCookie) => {
		if (userCookie) {
			return jwt(userCookie);
		}
	};

	const getUser = useCallback(() => {
		const userCookie = getUserCookie();
		const parsedUser = parseUserCookie(userCookie);
		return parsedUser;
	}, []);

	const populateCart = useCallback(async () => {
		const cart = await getCart();
		setCart(cart || []);
	}, []);

	useEffect(() => {
		const parsedUser = getUser();
		setUser(parsedUser);
	}, [getUser]);

	useEffect(() => {
		if (user && !user.is_admin) {
			populateCart();
		}
	}, [user, populateCart]);

	if (!user) {
		return <Loading />;
	}

	return (
		<UserContext.Provider value={user}>
			<CartContext.Provider
				value={{
					cart,
					setCart,
					setCartDrawerVisible,
					populateCart,
					addToCart,
					cartDrawerVisible,
					cartLoading,
					setCartLoading,
					updateCart,
				}}>
				<div className="flex flex-col min-h-screen bg-gray-100">
					<Header
						cartDrawerVisible={cartDrawerVisible}
						setCartDrawerVisible={setCartDrawerVisible}
						logout={logout}
					/>
					<div className="p-4 md:p-10 md:px-20 flex flex-col flex-1">
						<Outlet />
						{children}
					</div>
					<CartDrawer />
				</div>
			</CartContext.Provider>
		</UserContext.Provider>
	);
};

export default Layout;
