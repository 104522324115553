import Cookies from "js-cookie";

const getJWTAuthorizationToken = async (username_or_email, password) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/login`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			username_or_email: username_or_email,
			password: password,
		}),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const jwt_authorization_token = await response.json();
	return jwt_authorization_token;
};

const sendForgotPasswordEmail = async (email_address) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/forgot-password`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			email: email_address,
		}),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	return;
};

const postResetPassword = async (email, password, reset_password_token) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/reset-password`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			email: email,
			password: password,
			token: reset_password_token,
		}),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	return;
};

const getBearerToken = () => {
	const token = Cookies.get("jwt_authorization");
	if (!token) {
		window.location = "/login?redirect_url=" + window.location.pathname;
		return;
	}
	return token;
};

export { getJWTAuthorizationToken, sendForgotPasswordEmail, postResetPassword, getBearerToken };
