import { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";

// Function imports
import { getOrders } from "../../api/user/order";

import Loading from "../../components/Layout/Loading";

const Orders = () => {
	const [orders, setOrders] = useState([]);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);

	const toast = useRef(null);

	useEffect(() => {
		populateOrders();
		document.title = "Orders";
	}, []);

	const populateOrders = async () => {
		setLoading(true);
		try {
			const orders = await getOrders();
			setOrders(orders);
		} catch (err) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: err.message,
			});
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <Loading />;

	return (
		<div className="bg-white p-6 rounded-xl">
			<Toast ref={toast} className="opacity-100" />
			<DataTable
				value={orders}
				sortMode="multiple"
				dataKey="ID"
				responsiveLayout="scroll"
				paginator
				rows={20}
				rowsPerPageOptions={[10, 20, 50]}
				totalRecords={orders.length}
				filters={{
					global: { value: search, matchMode: FilterMatchMode.CONTAINS },
				}}
				header={
					<div className="w-full md:flex items-center justify-between">
						<h3 className="text-xl font-bold">Orders</h3>

						<div className="flex items-center md:justify-end gap-2 flex-wrap">
							<span className="p-input-icon-left md:w-auto w-full">
								<i className="pi pi-search" />
								<InputText
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									placeholder="Search"
									className="p-inputtext-sm w-full"
								/>
							</span>
						</div>
					</div>
				}>
				<Column field="order_number" header="OrderNumber" sortable></Column>
				<Column
					header="Status"
					sortable
					field="status"
					body={(rowData) => (
						<Tag
							value={
								rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1).replace("_", " ")
							}
							severity={
								rowData.status === "awaiting_shipment"
									? "warning"
									: rowData.status === "shipped"
									? "success"
									: "danger"
							}
						/>
					)}></Column>
				<Column
					header="Tracking Number"
					field="tracking_number"
					body={(rowData) =>
						rowData.tracking_number ? (
							<a
								className="text-blue-500 hover:text-blue-400 transition"
								target="_blank"
								rel="noreferrer"
								href={`https://www.aftership.com/track/${rowData.tracking_number}`}>
								{rowData.tracking_number}
							</a>
						) : (
							"N/A"
						)
					}
					sortable></Column>
				<Column header="Recipient" sortable field="ship_to.name"></Column>
				<Column
					header="Date Shipped"
					sortable
					field="ship_date"
					body={(rowData) => {
						// make sure the date is not a filler date
						if (rowData.ship_date === "0001-01-01T00:00:00Z") return "N/A";

						const date = new Date(rowData.ship_date);
						return date.toLocaleString();
					}}></Column>
				<Column
					header="Date Ordered"
					sortable
					field="date_ordered"
					body={(rowData) => {
						if (rowData.order_date === "0001-01-01T00:00:00Z") return "N/A";

						const date = new Date(rowData.order_date);
						return date.toLocaleString();
					}}></Column>
			</DataTable>
		</div>
	);
};

export default Orders;
