import { getBearerToken } from "../auth";

const getOrders = async () => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/orders`, {
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const orders = await response.json();
	return orders;
};

export { getOrders };
