import { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import NewUserDialog from "../../components/Admin/Users/New-User-Dialog";
import DeleteUsersDialog from "../../components/Admin/Users/Delete-Users-Dialog";
import EditUserDialog from "../../components/Admin/Users/Edit-User-Dialog";
import Loading from "../../components/Layout/Loading";

// Function imports
import { getUsers } from "../../api/admin/users";

const AdminUsers = () => {
	const [users, setUsers] = useState([]);
	const [search, setSearch] = useState("");
	const [selectedUsers, setSelectedUsers] = useState(null);
	const [loading, setLoading] = useState(false);

	const toast = useRef(null);

	useEffect(() => {
		populateUsers();
		document.title = "Users";
	}, []);

	const populateUsers = async () => {
		setLoading(true);
		try {
			const data = await getUsers();
			setUsers(data);
		} catch (err) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: err.message,
			});
		} finally {
			setLoading(false);
		}
	};

	if (loading) return <Loading />;

	return (
		<div className="bg-white p-6 rounded-xl">
			<Toast ref={toast} className="opacity-100" />
			<DataTable
				value={users}
				sortMode="multiple"
				selection={selectedUsers}
				onSelectionChange={(e) => setSelectedUsers(e.value)}
				selectionMode="checkbox"
				dataKey="ID"
				responsiveLayout="scroll"
				rowsPerPageOptions={1}
				filters={{
					global: { value: search, matchMode: FilterMatchMode.CONTAINS },
				}}
				header={
					<div className="w-full md:flex items-center justify-between">
						<h3 className="text-xl font-bold">Users</h3>

						<div className="flex items-center md:justify-end gap-2 flex-wrap">
							<NewUserDialog toast={toast} populateUsers={populateUsers} />
							<DeleteUsersDialog
								toast={toast}
								selectedUsers={selectedUsers}
								populateUsers={populateUsers}
							/>
							<span className="p-input-icon-left md:w-auto w-full">
								<i className="pi pi-search" />
								<InputText
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									placeholder="Search"
									className="p-inputtext-sm w-full"
								/>
							</span>
						</div>
					</div>
				}>
				<Column selectionMode="multiple" style={{ width: "3rem" }}></Column>
				<Column field="ID" header="ID" sortable></Column>
				<Column
					field="name"
					body={(user) => user.first_name + " " + user.last_name}
					header="Name"
					sortable></Column>
				<Column field="username" header="Username" sortable></Column>
				<Column field="email" header="Email" sortable></Column>
				<Column field="client_id" header="Client ID" sortable></Column>
				<Column
					field="role"
					body={(user) => (user.is_admin ? "Admin" : "User")}
					header="Role"
					sortable></Column>
				<Column
					field="CreatedAt"
					body={(user) => new Date(user.CreatedAt).toLocaleString()}
					header="Created"
					sortable></Column>
				<Column
					body={(user) => (
						<EditUserDialog user={user} toast={toast} populateUsers={populateUsers} />
					)}
				/>
			</DataTable>
		</div>
	);
};

export default AdminUsers;
