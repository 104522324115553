import { useState, useEffect } from "react";

// Component Imports
import Loading from "../../components/Layout/Loading";
import ProductGridItem from "../../components/Shop/Product-Grid-Item";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";

// Function Imports
import { getProducts } from "../../api/shop/products";

const Products = () => {
	const [products, setProducts] = useState([]);
	const [displayProducts, setDisplayProducts] = useState([]);

	const [loading, setLoading] = useState(true);

	const [first, setFirst] = useState(0);
	const [rows, setRows] = useState(15);
	const [totalRecords, setTotalRecords] = useState(0);

	useEffect(() => {
		populateProducts();
		document.title = "Hoopswagg Shop";
	}, []);

	const populateProducts = async () => {
		try {
			const products = await getProducts();

			// Sort Products with reseller limited products first
			products.sort((a, b) => {
				if (a.reseller_limited && !b.reseller_limited) {
					return -1;
				} else if (!a.reseller_limited && b.reseller_limited) {
					return 1;
				} else {
					return 0;
				}
			});

			setProducts(products);
			setDisplayProducts(products);
			setTotalRecords(products.length);
		} catch (error) {
			setProducts([]);
		} finally {
			setLoading(false);
		}
	};

	const onProductsSearch = (e) => {
		const search = e.target.value;
		const filteredProducts = products.filter((product) => {
			return product.name.toLowerCase().includes(search.toLowerCase());
		});

		// sort filtered products
		filteredProducts.sort((a, b) => {
			if (a.reseller_limited && !b.reseller_limited) {
				return -1;
			} else if (!a.reseller_limited && b.reseller_limited) {
				return 1;
			} else {
				return 0;
			}
		});

		setDisplayProducts(filteredProducts);
		setTotalRecords(filteredProducts.length);
	};

	if (loading) {
		return <Loading />;
	}

	return (
		<div>
			<div className="my-2">
				<div className="w-full md:flex items-center justify-between">
					<h3 className="w-full text-2xl font-semibold">Products</h3>
					<span className="block p-input-icon-left w-full md:w-auto mt-3 md:mt-0">
						<i className="pi pi-search" />
						<InputText
							placeholder="Search"
							className="p-inputtext-sm w-full md:w-auto"
							onInput={onProductsSearch}
						/>
					</span>
				</div>
				<Divider />

				{displayProducts.length ? (
					<>
						<div className="grid grid-cols-1 md:grid-cols-5 gap-4 auto-rows-fr">
							{displayProducts.slice(first, first + rows).map((product) => (
								<ProductGridItem product={product} key={product.SKU} />
							))}
						</div>
						<Paginator
							rows={rows}
							totalRecords={totalRecords}
							rowsPerPageOptions={[15, 30, 60]}
							className="my-4"
							first={first}
							onPageChange={(e) => {
								setFirst(e.first);
								setRows(e.rows);
							}}
						/>
					</>
				) : (
					<div className="py-20 flex items-center justify-center w-full">
						<h3 className="text-2xl text-gray-400 w-full text-center">No Results</h3>
					</div>
				)}
			</div>
		</div>
	);
};

export default Products;
