import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

import { useContext } from "react";
import CartContext from "../../context/cart";

const CartItem = ({ item, toast }) => {
	const { updateCart, populateCart, setCartLoading } = useContext(CartContext);

	const handleQuantityChange = async (newQuantity) => {
		setCartLoading(true);
		try {
			const updates = {};
			updates[item.ID] = newQuantity;
			await updateCart(updates);
			await populateCart();
		} catch (error) {
			toast.current.show({ severity: "error", detail: error.message, life: 3000 });
		} finally {
			setCartLoading(false);
		}
	};

	return (
		<div className="w-full p-2 flex gap-1">
			<div className="w-1/4">
				<Image src={item.image_url} alt={item.name} className="w-full" />
			</div>
			<div className="w-1/2">
				<h3 className="text-sm tracking-tighter">{item.name}</h3>
				{item.options &&
					item.options.length > 0 &&
					item.options.map((option, i) => {
						const optionName = option.name
							.charAt(0)
							.toUpperCase()
							.concat(option.name.slice(1).replace(/_/g, " "));

						let optionValue = "";

						if (optionName.includes("Image")) {
							optionValue = (
								<div className="w-5">
									<Image
										src={`https://ucarecdn.com/${option.value}/`}
										alt={option.name}
										className="object-cover"
										preview
									/>
								</div>
							);
						} else if (optionName.includes("background")) {
							optionValue = option.value
								.charAt(0)
								.toUpperCase()
								.concat(option.value.slice(1).replace(/_/g, " "));
						} else {
							optionValue = option.value;
						}

						return (
							<div className="text-xs text-gray-500 truncate flex items-center gap-2" key={i}>
								{optionName}: {optionValue}
							</div>
						);
					})}
				<p className="font-semibold mt-1">${(item.quantity * item.price).toFixed(2)}</p>
			</div>

			<div className="w-1/4 flex flex-col justify-between items-end pr-1">
				<Button
					icon="pi pi-times"
					className="p-button-rounded p-button-secondary p-button-text"
					onClick={() => handleQuantityChange(0)}></Button>

				<InputNumber
					name="quantity"
					inputStyle={{ textAlign: "center" }}
					value={item.quantity}
					showButtons
					buttonLayout="horizontal"
					step={1}
					min={0}
					onChange={(e) => handleQuantityChange(e.value)}
					decrementButtonClassName="p-button-secondary"
					incrementButtonClassName="p-button-secondary"
					incrementButtonIcon="pi pi-plus"
					decrementButtonIcon="pi pi-minus"
					size={1}
					className="h-8"
				/>
			</div>
		</div>
	);
};

export default CartItem;
