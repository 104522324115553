import { useEffect, useState, useCallback } from "react";

import { Dropdown } from "primereact/dropdown";

import { getBackgrounds } from "../../../api/shop/products";

const BackgroundSelect = ({
	customOptions,
	setCustomOptions,
	customOptionKey,
	toast,
	required,
}) => {
	const [backgrounds, setBackgrounds] = useState([]);

	const populateBackgrounds = useCallback(async () => {
		try {
			const backgrounds = await getBackgrounds();
			const backgroundsOptions = backgrounds.map((background) => ({
				label: (background.charAt(0).toUpperCase() + background.slice(1)).replaceAll("_", " "),
				value: background,
			}));
			setBackgrounds(backgroundsOptions);
		} catch (error) {
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: error.message,
			});
		}
	}, [toast]);

	useEffect(() => {
		populateBackgrounds();
	}, [populateBackgrounds]);

	const handleBackgroundSelect = (event) => {
		setCustomOptions({
			...customOptions,
			background: event.value,
		});
	};

	return (
		<div>
			<label className="my-2 font-semibold block">
				{(customOptionKey.charAt(0).toUpperCase() + customOptionKey.slice(1)).replaceAll("_", " ")}:{" "}
				{required && <span className="text-red-500">*</span>}
			</label>
			<Dropdown
				options={backgrounds}
				filter
				showClear
				filterBy="label"
				className="w-full"
				value={customOptions.background ? customOptions.background : null}
				placeholder="Select a background"
				onChange={handleBackgroundSelect}></Dropdown>
		</div>
	);
};

export default BackgroundSelect;
