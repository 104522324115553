import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "primereact/resources/themes/saga-blue/theme.css"; //theme
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import Cookies from "js-cookie";
import jwt from "jwt-decode";

// Component Imports
import Layout from "./components/Layout/Layout";
import NotFound from "./components/Layout/Not-Found";

// Auth Routes Imports
import Login from "./routes/auth/login";
import ForgotPassword from "./routes/auth/forgot-password";
import ForgotPasswordEmailSent from "./routes/auth/forgot-password-email-sent";
import ResetPassword from "./routes/auth/reset-password";
import ResetPasswordSuccess from "./routes/auth/reset-password-success";

// Admin Routes Imports
import AdminClients from "./routes/admin/clients";
import AdminUsers from "./routes/admin/users";
import AdminDiscounts from "./routes/admin/discounts";
import AdminOrders from "./routes/admin/orders";

// User Routes Imports
import Orders from "./routes/user/orders";

// Store Routes Imports
import Products from "./routes/shop/products";
import Product from "./routes/shop/product";
import Checkout from "./routes/shop/checkout";
import CheckoutSuccess from "./routes/shop/checkout-success";

import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";

const redirectIfUserIsLoggedIn = () => {
	if (Cookies.get("jwt_authorization")) {
		return redirect("/");
	}

	return null;
};

const redirectIfUserIsNotLoggedIn = () => {
	if (!Cookies.get("jwt_authorization")) {
		return redirect(`/login?redirect_url=${window.location.pathname}`);
	}

	return null;
};

const authorizeAdminAccess = (redirect_url = "/") => {
	const jwtToken = Cookies.get("jwt_authorization");
	if (!jwtToken) {
		return redirect(`/login?redirect_url=${window.location.pathname}`);
	}

	const user = jwt(jwtToken);
	if (!user.is_admin) {
		return redirect(redirect_url);
	}

	return null;
};

const redirectToCorrectHomePage = () => {
	const jwtToken = Cookies.get("jwt_authorization");
	if (!jwtToken) {
		return redirect(`/login?redirect_url=${window.location.pathname}`);
	}

	const user = jwt(jwtToken);
	if (!user.is_admin) {
		return redirect("/products");
	}

	return redirect("/admin/orders");
};

const router = createBrowserRouter([
	{
		path: "/login",
		element: <Login />,
		loader: redirectIfUserIsLoggedIn,
	},
	{
		path: "/forgot-password",
		element: <ForgotPassword />,
		loader: redirectIfUserIsLoggedIn,
	},
	{
		path: "/forgot-password/sent",
		element: <ForgotPasswordEmailSent />,
		loader: redirectIfUserIsLoggedIn,
	},
	{
		path: "/reset-password",
		element: <ResetPassword />,
	},
	{
		path: "/reset-password/success",
		element: <ResetPasswordSuccess />,
	},
	{
		path: "/",
		element: <Layout />,
		errorElement: (
			<Layout>
				<NotFound />
			</Layout>
		),
		loader: redirectIfUserIsNotLoggedIn,
		children: [
			{
				path: "/admin",
				loader: authorizeAdminAccess,
				children: [
					{
						path: "/admin/clients",
						element: <AdminClients />,
					},
					{
						path: "/admin/users",
						element: <AdminUsers />,
					},
					{
						path: "/admin/discounts",
						element: <AdminDiscounts />,
					},
					{
						path: "/admin/orders",
						element: <AdminOrders />,
					},
				],
			},
			{
				path: "/products",
				element: <Products />,
			},
			{
				path: "/product/:sku",
				element: <Product />,
			},
			{
				path: "/checkout",
				element: <Checkout />,
			},
			{
				path: "/checkout/success",
				element: <CheckoutSuccess />,
			},
			{
				path: "/orders",
				element: <Orders />,
			},
			{
				path: "/",
				loader: redirectToCorrectHomePage,
			},
		],
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
