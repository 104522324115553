import { ProgressSpinner } from "primereact/progressspinner";

const Loading = ({ hidden }) => {
	return (
		<div className={`${hidden ? "hidden" : "flex"} items-center justify-center w-full h-full grow`}>
			<ProgressSpinner />
		</div>
	);
};

export default Loading;
