import { getBearerToken } from "../auth";

const getAdminOrders = async () => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/admin/orders`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const clients = await response.json();
	return clients;
};

export { getAdminOrders };
