import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Component Imports
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

// Function Imports
import { postResetPassword } from "../../api/auth";

const ResetPassword = () => {
	const navigate = useNavigate();

	const [formLoading, setFormLoading] = useState(false);
	const [formError, setFormError] = useState("");

	useEffect(() => {
		document.title = "Reset Password";
	}, []);

	const submitResetPasswordForm = async (e) => {
		e.preventDefault();
		setFormLoading(true);

		try {
			const email = getEmailFromURLQuery();
			if (email === "") throw new Error("Invalid Password Reset Link");

			const token = getTokenFromURLQuery();
			if (token === "") throw new Error("Invalid Password Reset Link");

			const password = e.target.password.value;
			if (password === "") throw new Error("Password is required");

			const confirm_password = e.target.confirm_password.value;
			if (confirm_password !== password) throw new Error("Passwords do not match");

			if (password.length < 8) throw new Error("Password must be at least 8 characters");

			await postResetPassword(email, password, token);
			setFormError("");
			navigateAfterPasswordReset();
		} catch (error) {
			setFormError(error.message);
		}

		setFormLoading(false);
	};

	const navigateAfterPasswordReset = () => {
		if (getIsFirstPasswordSetFromURLQuery() === "true") {
			navigate("/reset-password/success?first=true");
			return;
		}

		navigate("/reset-password/success");
	};

	const getTokenFromURLQuery = () => {
		const searchParams = new URLSearchParams(window.location.search);
		return searchParams.get("token");
	};

	const getEmailFromURLQuery = () => {
		const searchParams = new URLSearchParams(window.location.search);
		return searchParams.get("email");
	};

	const getIsFirstPasswordSetFromURLQuery = () => {
		const searchParams = new URLSearchParams(window.location.search);
		return searchParams.get("first");
	};

	return (
		<div className="bg-gray-100 h-screen w-screen flex items-center justify-center">
			<Card className="bg-white w-screen md:max-w-lg h-screen md:h-auto">
				<img
					src="https://cdn.shopify.com/s/files/1/0440/5285/files/hs-logo1_120x.png?v=1617341324"
					alt=""
					className="mx-auto mb-8 "
				/>

				{/* form error */}
				{formError && (
					<div
						className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
						role="alert">
						<span className="block sm:inline">{formError}</span>
					</div>
				)}

				<form onSubmit={submitResetPasswordForm}>
					<div className="w-full mt-4">
						<label htmlFor="password" className="text-black block mb-1">
							{getIsFirstPasswordSetFromURLQuery() === "true" ? "Password" : "New Password"}
						</label>
						<Password
							name="password"
							id="password"
							className="w-full"
							inputClassName="w-full"
							toggleMask={true}
							feedback={false}
							disabled={formLoading}
						/>
					</div>
					<div className="w-full mt-4">
						<label htmlFor="confirm_password" className="text-black block mb-1">
							Confirm Password
						</label>
						<Password
							name="confirm_password"
							id="confirm_password"
							className="w-full"
							inputClassName="w-full"
							toggleMask={true}
							feedback={false}
							disabled={formLoading}
						/>
					</div>

					{getIsFirstPasswordSetFromURLQuery() !== "true" && (
						<div className="w-full mt-2">
							<Link to="/login" className="text-blue-700 hover:text-blue-600 transition">
								Back to Login
							</Link>
						</div>
					)}

					<div className="w-full mt-4">
						<Button
							label={
								getIsFirstPasswordSetFromURLQuery() === "true" ? "Set Password" : "Reset Password"
							}
							className="w-full"
							loading={formLoading}
						/>
					</div>
				</form>
			</Card>
		</div>
	);
};

export default ResetPassword;
