import { getBearerToken } from "../auth";

const getSavedCreditCards = async () => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/checkout/saved-credit-cards`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const savedCreditCards = await response.json();
	return savedCreditCards;
};

const completeCheckout = async (checkoutData) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/checkout`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify(checkoutData),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const order = await response.json();
	return order;
};

const getDiscount = async (discount_code) => {
	const response = await fetch(
		`${process.env.REACT_APP_API_ADDRESS}/checkout/discount/${discount_code}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: getBearerToken(),
			},
		}
	);

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const discount = await response.json();
	return discount;
};

export { getSavedCreditCards, completeCheckout, getDiscount };
