import { Card } from "primereact/card";
import { Button } from "primereact/button";

import { useEffect } from "react";
import { Link } from "react-router-dom";

const CheckoutSuccess = () => {
	const URLQueryParams = new URLSearchParams(window.location.search);
	const orderNumber = URLQueryParams.get("order_number");

	useEffect(() => {
		document.title = "Order Placed Successfully";
	}, []);

	return (
		<div className="h-full w-full flex items-center justify-center flex-1">
			<Card title="Order Placed Successfully" className="text-center p-6 px-12">
				<p>Thank you for your purchase!</p>
				{orderNumber && (
					<p>
						Your order number is <strong>{orderNumber}</strong>
					</p>
				)}
				<p>You can view your order history on the orders page.</p>
				<Link to="/orders" className="block mt-4">
					<Button label="View Orders" />
				</Link>
			</Card>
		</div>
	);
};

export default CheckoutSuccess;
