import { getBearerToken } from "../auth";

const getCart = async () => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/cart`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	const cart = await response.json();
	return cart;
};

const addToCart = async (item) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/cart/add`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify(item),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	return;
};

const updateCart = async (updates) => {
	const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}/cart/update`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: getBearerToken(),
		},
		body: JSON.stringify(updates),
	});

	if (!response.ok) {
		const errorText = await response.text();
		throw new Error(errorText);
	}

	return;
};

export { getCart, addToCart, updateCart };
