import { Card } from "primereact/card";
import { Link } from "react-router-dom";

const NotFound = () => {
	return (
		<div className="w-full h-full grow flex items-center justify-center px-4">
			<Card title="404" subTitle="Page not found" className="text-center">
				<p>Sorry, the page you are looking for could not be found.</p>
				<Link to="/" className="text-blue-500 hover:text-blue-400 transition mt-2 block">
					Go to Home
				</Link>
			</Card>
		</div>
	);
};

export default NotFound;
